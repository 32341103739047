<template>
  <div class="all">
    <AppHeader active-index="/app"></AppHeader>
    <div class="main">
      <div :style="background" style="width: 100vw;height: 50vw;min-width: 960px;min-height: 480px;
      display: flex;align-items: center;">
        <div style="background: rgba(255,255,255,0.3);height: 28.125vw;width: 37.5vw;
        min-height:270px;min-width:360px;padding: 40px;margin-left: 10%">
          <div style="height:50%;display: flex;align-items: center;">
            <el-image :src="require('../../assets/App/2.png')" lazy style="height: 7vw;width:7vw;min-width:67px;
            min-height:67px;float: left"/>
            <div style="float: left;color: white;text-align: left;margin-left: 2%">
              <h1 style="line-height: 36px;">双动APP</h1>
              <p style="font-weight: 500;line-height: 36px">多功能运动伴侣，健康好教练</p>
            </div>
          </div>

          <div style="clear: both"></div>

          <div style="height: 50%;">
            <div style="float: left;">
              <a href="https://apps.apple.com/cn/app/id1459468902">
                <el-image class="opacity" :src="require('../../assets/App/3.png')" lazy style="display: block;width:17vw;height:5vw;min-height: 48px;min-width: 163px"/>
              </a>
              <div style="height: 1vw;min-height: 9px"></div>
              <a href="https://app.oss.toodo.com.cn/apk/toodo_app.apk">
                <el-image class="opacity" :src="require('../../assets/App/4.png')" lazy style="display: block;width:17vw;height:5vw;min-height: 48px;min-width: 163px"/>
              </a>
            </div>
            <el-image :src="require('../../assets/App/5.png')" lazy style="float: right;
            width:11vw;height:11vw;min-height: 105px;min-width: 105px;"/>
          </div>
        </div>
      </div>

      <div style="margin: 0 10%;text-align: left">
        <el-row :gutter="30" style="margin-top: 20px;">
          <el-col :span="12">
            <router-link to="/course">
              <el-card shadow="hover"
                       style="min-height: 253px;border: 0">
                <div style="position: absolute;color: white;margin: 20px">
                  <h2>运动课程</h2>
                  <p style="margin-top: 10px;">海量视频课程，专业动作指导，智能训练计划</p>
                </div>
                <img src="../../assets/App/抱头.jpg" style="height: 100%;width: 100%;display: block"/>
              </el-card>
            </router-link>
          </el-col>
          <el-col :span="12">
            <router-link to="/recovery">
              <el-card shadow="hover"
                       style="min-height: 253px;border: 0;">
                <div style="position: absolute;color: white;margin: 20px">
                  <h2>训练康复</h2>
                  <p style="margin-top: 10px;">损伤康复，慢性病康复</p>
                </div>
                <img src="../../assets/App/老人.jpg" style="height: 100%;width: 100%;display: block"/>
              </el-card>
            </router-link>
          </el-col>
        </el-row>

        <el-row :gutter="30" style="margin-top: 20px;margin-bottom: 20px">
          <el-col :span="12">
            <router-link to="/food">
              <el-card shadow="hover"
                       style="min-height: 253px;border: 0">
                <div style="position: absolute;color: white;margin: 20px">
                  <h2>健康饮食</h2>
                  <p style="margin-top: 10px;">丰富健康饮食，精准营养搭配</p>
                </div>
                <img src="../../assets/App/食物.jpg" style="height: 100%;width: 100%;display: block"/>
              </el-card>
            </router-link>
          </el-col>
          <el-col :span="12">
            <router-link to="/consultant">
              <el-card shadow="hover"
                       style="min-height: 253px;border: 0">
                <div style="position: absolute;color: white;margin: 20px">
                  <h2>专家顾问</h2>
                  <p style="margin-top: 10px;">众多专业顾问，国家健将级运动员，运动康复博士，体育硕士研究生导师</p>
                </div>
                <img src="../../assets/App/专家.jpg" style="height: 100%;width: 100%;display: block"/>
              </el-card>
            </router-link>
          </el-col>
        </el-row>
      </div>

<!--      <div class="divtest sdiv" data-animation="on">-->
        <div style="position: relative">
          <div style="position: absolute;top:50%;left:50%;transform: translate(-50%,-50%)">
              <div style="width: 30%;float: left;margin-left:-10%;">
                <div class="divtest sdiv" data-animation="on">
                <h2>关爱老人</h2>
                <h3 style="margin: 10px 0;">感动触手可及!</h3>
                <p style="font-weight: 500;line-height: 25px;text-align: left">实时观测老人心率、睡眠、血氧、血压、计步、运动轨迹与频率、定位、一键呼叫、电子围栏、趣味性康复教程等。</p>
              </div>
            </div>
            <el-image :src="require('../../assets/App/竖屏手机.png')" lazy style="width: 15vw;height:30vw;min-width:144px;min-height:288px;
            position: absolute;top: 50%;transform: translateY(-50%);margin-left: 10%"/>
          </div>

          <img src="../../assets/App/手.jpg"
               style="width: 100vw;height: 50vw;min-height:480px;min-width:960px;display: block"/>
        </div>
<!--      </div>-->

<!--      <div class="divtest sdiv" data-animation="on">-->
        <div style="position: relative">
          <div style="position: absolute;top: 50%;transform: translate(-50%,-50%);left: 50%;">
            <el-image :src="require('../../assets/App/横屏手机.png')" lazy style="width: 30vw;height:15vw;min-width:288px;min-height:144px;float: left;
            margin-left: -20%"/>
            <div style="color: white;float: left;width: 30%;margin-left: 20%">
              <div class="divtest sdiv" data-animation="on">
              <h2>关心健康</h2>
              <h3 style="margin: 10px 0;">您的运动健身助手!</h3>
              <p style="font-weight: 500;line-height: 25px;text-align: left">
                智能运动监测记录、海量专业动作指导视频、 个性化课程定制、智能训练计划、丰富健康食谱搭配推荐、丰富的运动经验分享。
              </p>
              </div>
            </div>
          </div>

          <img src="../../assets/App/哑铃.jpg"
               style="width: 100vw;height: 50vw;min-height:480px;min-width:960px;"/>
        </div>
<!--      </div>-->

      <div class="divtest sdiv" data-animation="on">
        <h2 style="padding-top:20px">关注睡眠</h2>
        <h3 style="padding: 10px">24小时全天候检测</h3>
        <p>记录睡眠情况，根据深浅睡眠时间，分析睡眠质量，睡眠健康画像，</p>
        <p style="padding: 10px 0 20px 0">分析睡眠影响原因，提供针对性的睡眠康复方案</p>
      </div>
<!--      <div class="divtest sdiv" data-animation="on">-->
        <el-image :src="require('../../assets/App/睡眠.jpg')" lazy
             style="width: 90vw;height: 32.5125vw;min-height:346px;min-width:960px;margin: 0 5%;display:block;"/>
<!--      </div>-->

<!--      <div class="divtest sdiv" data-animation="on">-->
        <div style="position: relative">
          <div style="position: absolute;top: 50%;transform: translateY(-50%);width: 30%;left: 20%">
<!--            <div class="divtest sdiv" data-animation="on">-->
            <h2>关怀儿童</h2>
            <h3 style="margin: 10px 0;">让宝贝健康快乐成长!</h3>
            <p style="font-weight: 500;line-height: 25px;text-align: left">
              计步、定位、运动姿势提醒等，有趣的体感互动与丰富的亲子互动视频，学习与娱乐时间管理提醒功能。
            </p>
<!--            </div>-->
          </div>


          <img src="../../assets/App/导航.jpg"
               style="width: 100vw;height: 50vw;min-height:480px;min-width:960px;display:block;"/>
        </div>
<!--      </div>-->

      <div style="background: #000;color: white;display: flex;align-items: center;
      justify-content: center;flex-direction: column">
<!--        <div class="divtest sdiv" data-animation="on">-->
          <h2 style="padding-top: 50px;padding-bottom:50px;">更多功能，就在双动APP！</h2>
          <div style="float: left;margin-bottom: 50px">
            <a href="https://apps.apple.com/cn/app/id1459468902">
              <el-image :src="require('../../assets/App/3.png')" lazy style="display:block;width:15vw;height:4.4vw;min-height: 42px;min-width: 144px"/>
            </a>
            <div style="height: 1vw;min-height: 9px"></div>
            <a href="https://app.oss.toodo.com.cn/apk/toodo_app.apk">
              <el-image :src="require('../../assets/App/4.png')" lazy style="display:block;width:15vw;height:4.4vw;min-height: 42px;min-width: 144px"/>
            </a>
          </div>
          <el-image :src="require('../../assets/App/5.png')" lazy style="float: left;
            margin-left: 10px;width:9.8vw;height:9.8vw;min-height: 93px;min-width: 93px;"/>
<!--        </div>-->
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import AppHeader from "../../components/AppHeader";
import Footer from "../../components/Footer";

export default {
  name: "App",
  data() {
    return {
      input: null,
      background: {
        background: 'url(' + require('../../assets/App/1.jpg') + ')',
        backgroundSize: '100% 100%',
      },
    }
  },
  props: {
    activeIndex: {
      type: String
    }
  },
  methods: {},
  components: {
    AppHeader, Footer
  }
}
</script>

<style scoped>
>>> .el-card__body {
  padding: 0;
}

.opacity:active{
  opacity: 0.85;
}

.all {
  min-width: 960px;
}

ul {
  float: left;
}

li {
  font-size: 12px;
  list-style: none;
  margin-top: 20px;
}

a:link, a:visited {
  color: #ffffff;
}

.all {
  background: white;
  margin: 0;
  padding: 0;
}

.main {
  text-align: center;
  /*height: 2500px;*/
  margin: 60px 0 0 0;
  padding: 0;
  overflow: hidden;
}

.router-link-active {
  text-decoration: none;
}

a {
  text-decoration: none;
}
</style>